import "./App.css";
import Carousel from "./Carousel/Carousel";
import Footer from "./Footer/Footer";
import Navbar from "./Navbar/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import QueroAjudar from "./QueroAjudar/QueroAjudar";
import Midias from "./Midias/Midias";
import Sobre from "./Sobre/Sobre";

function App() {
  return (
    <div className="App">
      <Navbar />
      <main style={{ backgroundColor: "#E4E4E4", paddingTop: "1.5rem" }}>
        <Carousel />
        <Sobre />
        <Midias />
        <QueroAjudar />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;
