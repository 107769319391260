import "./Carousel.css";

function Carousel() {
  return (
    <div class="container pt-2 pb-2">
      <div
        id="carouselExampleAutoplaying"
        class="carousel slide "
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="carousel10.jpeg"
              class="img-custom-carousel d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="carousel11.jpg"
              class="img-custom-carousel d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="carousel12.jpg"
              class="img-custom-carousel d-block w-100"
              alt="..."
            />
          </div>
          <div class="carousel-item">
            <img
              src="carousel13.jpg"
              class="img-custom-carousel d-block w-100"
              alt="..."
            />
          </div>
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleAutoplaying"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
export default Carousel;
