import "./Navbar.css";

function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg border-body fixed-top"
      data-bs-theme="light"
      style={{ paddingTop: "0px", paddingBottom: "0px" }}
    >
      <div className="container-fluid">
        <a
          className="navbar-brand d-flex align-items-center personalized-a-title"
          href="#"
          style={{
            paddingTop: "0px",
            paddingBottom: "0px",
            marginRight: "0px",
          }}
        >
          <img
            src="logo-valentes-davi.png"
            className="img-custom-logo"
            alt="Logo"
          />
          <h1 className="title-page-custom mb-0">
            Projeto <span>Valentes de Davi</span>
          </h1>
        </a>
        <button
          className="navbar-toggler me-ls-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse me-lg-5"
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav mb-2 mb-lg-0 ps-lg-0 ">
            <li className="nav-item me-lg-3">
              <a className="nav-link" aria-current="page" href="#quem-somos">
                Quem somos
              </a>
            </li>
            <li className="nav-item me-lg-3">
              <a className="nav-link" href="#midias">
                Vídeos
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#contato">
                Contato
              </a>
            </li>
          </ul>
          <a
            type="submit"
            className="btn ms-lg-4 send-button-custom"
            href="#quero-ajudar"
          >
            Quero ajudar
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
