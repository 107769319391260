import "./Midias.css";

function Midias() {
  return (
    <section id="midias" className="midia-container">
      <h2 className="titulo-midias-section">Mídias</h2>
      <div className="reportagem-completa-container">
        <a href="https://fb.watch/sIa1luqEdo/" target="_blank">
          <img
            className="img-fluid"
            src="sbt-midia-img.png"
            alt="Imagem do vídeo no Youtube da reportagem SBT - Valentes de Davi atuando nas enchentes do Rio
            Grande do Sul"
          />
        </a>

        <div className="ps-reportagens">
          <p className="titulo-reportagens">
            Reportagem SBT - Valentes de Davi atuando nas enchentes do Rio
            Grande do Sul
          </p>
          <p className="data-reportagens">06/2018</p>
        </div>
      </div>
      <div className="reportagem-completa-container">
        <a href="https://fb.watch/sI9ZxbcAie/" target="_blank">
          <img
            className="img-fluid"
            src="band-midia-img.png"
            alt="Imagem do vídeo no Youtube da reportagem Band - ONG Valentes de Davi e suas atividades nas
            comunidades carentes"
          />
        </a>
        <div className="ps-reportagens">
          <p className="titulo-reportagens">
            Reportagem Band - ONG Valentes de Davi e suas atividades nas
            comunidades carentes
          </p>
          <p className="data-reportagens">03/2019</p>
        </div>
      </div>
      <div className="reportagem-completa-container">
        <a href="https://fb.watch/sI9VUx4EyK/" target="_blank">
          <img
            className="img-fluid"
            src="record-midia-img.png"
            alt="Imagem do vídeo no Youtube da reportagem Record - Valentes de Davi e sua historia nas comunidades
            carentes de Porto Alegre"
          />
        </a>
        <div className="ps-reportagens">
          <p className="titulo-reportagens">
            Reportagem Record - Valentes de Davi e sua historia nas comunidades
            carentes de Porto Alegre
          </p>
          <p className="data-reportagens">11/2017</p>
        </div>
      </div>
    </section>
  );
}

export default Midias;
