import React, { useState } from "react";
import "./QueroAjudar.css";

function QueroAjudar() {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const cnpj = "39860600000165";
    navigator.clipboard.writeText(cnpj).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000);
      },
      () => {
        alert("Falha ao copiar o CNPJ.");
      }
    );
  };

  return (
    <div id="quero-ajudar" className="container-quero-ajudar">
      <div
        className="pb-5 pt-5 container-custom-quero-ajudar-title"
        style={{ width: "100%" }}
      >
        <h2 className="custom-quero-ajudar-title ">QUERO AJUDAR</h2>
      </div>
      <div className="custom-container-quero-ajudar">
        <div className="custom-out-container-doe-pix">
          <div className="custom-container-doe-pix pt-5 pb-5">
            <h3>DOE DE FORMA RÁPIDA COM O PIX</h3>
            <h4>Como faço para doar com PIX?</h4>
            <p className="passo-a-passo-pix">
              1. Acesse o Pix dentro do app do seu banco.
            </p>
            <p className="passo-a-passo-pix">
              2. Selecione “pagar com chave pix”;
            </p>
            <p className="passo-a-passo-pix">
              3. Selecione a opção CNPJ, digite o CNPJ abaixo e pronto!
            </p>
          </div>
        </div>
        <div className="cnpj-container">
          <p className="custom-pix-cnpj">
            CNPJ 39860600000165
            <button className="copy-button" onClick={copyToClipboard}>
              <i className="fa-regular fa-copy custom-icon-copy"></i>
            </button>
          </p>
          {copied && (
            <p className="copy-message mt-3">
              CNPJ copiado para a área de transferência!
            </p>
          )}
        </div>

        <p className="outra-forma-text pt-5 pb-2">
          Quer doar ou ajudar de outra forma?
        </p>
        <p className="fale-conosco-text">
          Fale conosco pelo nosso Whatsapp{" "}
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=5551984182343&text=Ol%C3%A1%2C+venho+pelo+site%21+Gostaria+de+ajudar+no+projeto+Valentes+de+Davi.+Podemos+conversar%3F&type=phone_number&app_absent=0"
            className=""
          >
            (51) 98418-2343
          </a>
        </p>
      </div>
    </div>
  );
}

export default QueroAjudar;
