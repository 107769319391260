import { useEffect } from "react";
import "./Sobre.css";

function Sobre() {
  useEffect(() => {
    const elements = document.querySelectorAll(".custom-caixa-sobre");

    const options = {
      root: null,
      rootMargin: "0px",
    };

    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-slide-in");
          observer.unobserve(entry.target);
        }
      });
    }, options);

    elements.forEach((element) => {
      element.classList.add("before-animation");
      observer.observe(element);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div id="quem-somos" className="container-sobre">
      <div className="background-div">
        <div className="container-sobre-title-custom">
          <h2 className="sobre-title-custom">O projeto</h2>
        </div>

        <div className="custom-caixa-sobre mb-5">
          <img className="img-fluid" src="projeto-img-1.png" alt="" />
          <div className="overflow-text">
            <p>Quem somos?</p>
            <p className="overflow-text-descricao">
              O Projeto Valentes de Davi, idealizado por José Eduardo da Silva
              Flores, tem como missão transformar a vida de crianças e famílias
              carentes na zona sul de Porto Alegre. Há 10 anos, este projeto
              impacta positivamente a comunidade, oferecendo não apenas
              refeições, mas também atividades recreativas e suporte emocional.
            </p>
          </div>
        </div>

        <div className="custom-caixa-sobre trocada mb-5">
          <div className="overflow-text">
            <p>Nossa história</p>
            <p className="overflow-text-descricao">
              José Eduardo da Silva Flores, o fundador do projeto, conhece bem
              as dificuldades das ruas. Ele passou 10 anos vivendo na
              dependência do crack e como morador de rua. Essa experiência o
              motivou a iniciar o Valentes de Davi, com a crença de que cuidar
              das crianças hoje é essencial para evitar problemas maiores no
              futuro, como o vício em drogas e a falta de moradia.
            </p>
          </div>
          <img className="img-fluid" src="projeto-img-2.png" alt="" />
        </div>

        <div className="custom-caixa-sobre mb-5">
          <img className="img-fluid" src="projeto-img-3.png" alt="" />
          <div className="overflow-text">
            <p>Ações e Impacto</p>
            <p className="overflow-text-descricao">
              O projeto atende atualmente mais de 200 crianças, fornecendo cerca
              de 2.000 refeições por mês. Inicialmente focado em ajudar
              moradores de rua, o Valentes de Davi expandiu suas atividades para
              incluir crianças, entendendo que elas são o futuro e que precisam
              de cuidados e atenção especiais. Além disso, o projeto estendeu
              seu auxílio às famílias, especialmente após as enchentes que
              aumentaram significativamente a necessidade de ajuda na região.
            </p>
          </div>
        </div>

        <div className="custom-caixa-sobre trocada mb-5">
          <div className="overflow-text">
            <p>Comunidades Beneficiadas</p>
            <p className="overflow-text-descricao">
              O projeto atua principalmente nos bairros mais pobres e esquecidos
              de Porto Alegre, como o Beco do Buda, Bairro Lajeado, Vila Arena,
              Vila Cobal, Beco X e Vila Carandiru. As comunidades mais afetadas
              pelas enchentes no Rio Grande do Sul incluem Vila Arena, Vila
              Cobal, Beco X e Vila Carandiru, onde muitas famílias perderam suas
              casas e pertences. Atualmente, o projeto atende diretamente 40
              famílias no Bairro Lajeado. Com a sede localizada no Partenon, que
              infelizmente foi destruída por um temporal, a necessidade de
              suporte se tornou ainda mais urgente.
            </p>
          </div>
          <img className="img-fluid" src="projeto-img-4.png" alt="" />
        </div>

        <div className="custom-caixa-sobre mb-5">
          <img className="img-fluid" src="projeto-img-5.png" alt="" />
          <div className="overflow-text">
            <p>Missão Além-Fronteiras</p>
            <p className="overflow-text-descricao">
              Além do trabalho local, José Eduardo da Silva Flores também
              ampliou as fronteiras do projeto ao levar a missão do Valentes de
              Davi para Senegal, na África. Ele foi até lá para ajudar crianças
              em situação de vulnerabilidade, mostrando que a solidariedade do
              projeto não conhece limites geográficos. Este espírito missionário
              reflete a dedicação incansável do projeto em fazer a diferença,
              não importa onde.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sobre;
