import "./Footer.css";

function Footer() {
  return (
    <div id="contato" className="footer-custom-container">
      <p className="mb-5 pt-5 p-footer-custom fs-3">Onde nos encontrar</p>
      <div className="pb-5 footer-socials-buttons-custom">
        <a
          target="_blank"
          href="https://www.instagram.com/projetovalentesdedavi/"
          className="radial-button socials-footer-button-custom"
        >
          <img class="img-fluid" src="logo-instagram.png" alt="" />
          Instagram
        </a>
        <a
          target="_blank"
          href="https://www.facebook.com/projetovalentesdedavi"
          className="socials-footer-button-custom facebook-custom-button"
        >
          <img class="img-fluid" src="logo-facebook.png" alt="" />
          Facebook
        </a>
        <a
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=5551984182343&text=Ol%C3%A1%2C+venho+pelo+site%21+Gostaria+de+ajudar+no+projeto+Valentes+de+Davi.+Podemos+conversar%3F&type=phone_number&app_absent=0"
          className="socials-footer-button-custom whats-custom-button"
        >
          <img class="img-fluid" src="logo-whats.png" alt="" />
          Whatsapp
        </a>
      </div>
      <div className="custom-footer-texts">
        <p className="mb-0 footer-text-custom">Associação Valentes de Davi ®</p>
        <p className="mb-0 footer-text-custom">Cnpj 39860600000165</p>
      </div>
    </div>
  );
}

export default Footer;
